<template>
  <!-- 销售属性 -->
  <template
    v-if="showSaleAttr"
  >
    <ProductCardSaleAttr 
      class="product-item__sale-attr"
      :sale-attr-label="saleAttrLabel"
    />
  </template>

  <!-- 右下角通用角标: 色块优先 但是没有色块数据 -->
  <template
    v-else-if="lowerRightInfo.hasLowerRightMaterial && lowerRightInfo.isShowLowerRightColorBlock === 'show' && showMultiColor && !hasRelatedColor"
  >
    <!-- 显示通用角标 -->
    <!-- null -->
  </template>

  <!-- 右下角通用角标: 角标优先 -->
  <template
    v-else-if="lowerRightInfo.hasLowerRightMaterial && lowerRightInfo.isShowLowerRightColorBlock === 'noShow'"
  >
    <!-- null -->
  </template>

  <!-- 属性类卖点: 属性优先 -->
  <template
    v-else-if="showPropertyUpsell && getCornerPropertyUpsell && getCornerPropertyUpsell.isShowColorBlock === 'noShow'"
  >
    <ProductCardPropertyUpsell
      :has-shaped-belt="hasShapedBelt"
      :get-corner-property-upsell="getCornerPropertyUpsell"
    />
  </template>

  <!-- 属性类卖点: 色块优先 -->
  <template
    v-else-if="showPropertyUpsell && getCornerPropertyUpsell && getCornerPropertyUpsell.isShowColorBlock === 'show'"
  >
    <div
      v-if="showMultiColor && hasRelatedColor"
      class="product-card__color-set-wrapper"
    >
      <ProductCardColorSet
        v-if="hasRelatedColor"
        direction="vertical"
        :related-color="relatedColor"
      />
    </div>
    <ProductCardPropertyUpsell
      v-else
      :has-shaped-belt="hasShapedBelt"
      :get-corner-property-upsell="getCornerPropertyUpsell"
    />
  </template>

  <template v-else>
    <!-- 未命中属性类卖点，则色块逻辑正常显示 -->
    <div
      v-if="showMultiColor"
      class="product-card__color-set-wrapper"
    >
      <ProductCardColorSet
        v-if="hasRelatedColor"
        direction="vertical"
        :related-color="relatedColor"
      />
    </div>
  </template>
</template>

<script>
import ProductCardColorSet from './ProductCardColorSet.vue'
import ProductCardPropertyUpsell from './ProductCardPropertyUpsell.vue'
import ProductCardSaleAttr from './ProductCardSaleAttr.vue'

export default {
  name: 'ProductCardImgRightBottom',
  components: {
    ProductCardColorSet,
    ProductCardPropertyUpsell,
    ProductCardSaleAttr

  },
  inject: {
    config: {
      from: 'config',
      default: () => ({}),
    },
  },
  props: {
    relatedColor: {
      type: Object,
      default: () => ({}),
    },
    getCornerPropertyUpsell: {
      type: Object,
      default: null
    },
    beltLabel: {
      type: Object,
      default: () => ({})
    },
    saleAttrLabel: {
      type: String,
      default: ''
    },
    lowerRightInfo: {
      type: Object,
      default: () => ({
        hasLowerRightMaterial: false,
        isShowLowerRightColorBlock: ''
      }),
    },
  },
  computed: {
    showMultiColor() {
      // showMultiColor: 是否展示色块
      // showMainImgColor: 是否关闭色块切换功能,使用主图色块
      return this.config.showMultiColor && this.config.showMainImgColor
    },
    showPropertyUpsell() {
      return this.config.showPropertyUpsell
    },
    hasRelatedColor() {
      return this.relatedColor?.data?.length > 1
    },
    hasShapedBelt() {
      return ['countdownAbShaped', 'discountAbShaped', 'willSaveAbShaped'].includes(this.beltLabel?.component)
    },
    showSaleAttr() {
      return this.config.showSaleAttr && this.saleAttrLabel
    }
  },
}
</script>

<style lang="less">
.product-card__color-set-wrapper {
  isolation: isolate;
  display: flex;
  flex-direction: column;
  align-items: self-end;
  direction: ltr /* rtl: ignore */;
}
.product-item__sale-attr {
  margin-bottom: 12px;
}
.S-product-card__wrapper-similar {
  .product-item__sale-attr {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
}
</style>
