
import { ref, computed } from 'vue'

import { clickAddToBagAna } from '../analysis'
import { reportMetricCount } from '../utils'

export const useAddToBag = ({
  imgContainer,
  emit,
  config,
  index,
  goodsInfo,
  imgRatio,
  saleout,
  getImgRatio,
  reportMetrics
}) => {

  const addBagLoading = ref(false)

  const showAddBagBtn = computed(() => {
    return config.showAddBagBtn && !saleout.value.show
  })
  
  // const handleSubmitAddBagAnalysis = (payload) => {
  //   emit('submitAddBagAnalysis', {
  //     ...payload,
  //     target: imgContainer.value.$el,
  //   })
  // }

  // 上报点击加车按钮监控指标
  const reportAddBagMetric = () => {
    const pageName = config?.commonParams?.pageName

    if (!reportMetrics?.addBag || !pageName) return

    reportMetricCount({
      metricName: 'click_to_addcart_total',
      tags: { page: pageName, ...(reportMetrics.tags || {}) },
      message: 'Number of click add bag button',
    })
  }

  const handelOpenQuickAdd = async () => {
    if (addBagLoading.value) return false
    addBagLoading.value = true
    setTimeout(() => {
      addBagLoading.value = false
    }, 1000)

    // 埋点
    !config.useOwnClickAddBagAnalysis && clickAddToBagAna({ target: imgContainer.value.$el, config })

    if (!imgRatio.value) {
      await getImgRatio()
    }

    reportAddBagMetric()

    emit('openQuickAdd', {
      item: goodsInfo.value,
      goodsItem: goodsInfo.value,
      target: imgContainer.value.$el,
      index,
      imgRatio: imgRatio.value
    })
  }

  return {
    addBagLoading,
    showAddBagBtn,
    handelOpenQuickAdd
  }
}

