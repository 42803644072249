
import { stringifyQueryString } from '@shein/common-function'

/**
* 获取榜单落地页链接
* @param {*} param0 
*/
export const getRankProductsUrl = ({
  langPath,
  params,
}) => {
  if (params.contentCarrierId) {
    params.page_from = window.getSaPageInfo?.page_name || params.page_from || '' // 榜单来源
    return `${langPath}/ranking_list?${stringifyQueryString({
      queryObj: params,
    })}`
  }
  return ''
}
