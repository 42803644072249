<template>
  <div class="product-card__property-upsell">
    <div
      v-if="upsellType === 'text'"
      :style="{ '--need-margin-bottom': hasShapedBelt ? '24px' : '4px' }"
      class="property-upsell__text"
    >
      {{ propertyUpsellText }}
    </div>
    <div v-if="upsellType === 'img' || upsellType === 'image'">
      <img
        class="property-upsell__img"
        :src="transformImg({ img: getCornerPropertyUpsell.icon })"
        alt="image for product card property upsell"
      />
    </div>
  </div>
</template>

<script name="ProductCardPropertyUpsell" setup>
import { computed, toRefs } from 'vue'
import { transformImg } from '@shein/common-function'

const props = defineProps({
  getCornerPropertyUpsell: {
    type: Object,
    default: null
  },
  hasShapedBelt: {
    type: Boolean,
    default: false
  }
})

const { getCornerPropertyUpsell } = toRefs(props)

const propertyUpsellText = computed(() => {
  return getCornerPropertyUpsell.value.textList?.[0]?.labelLang || ''
})

const upsellType = computed(() => {
  return getCornerPropertyUpsell.value?.type || ''
})
</script>

<style lang="less">
.product-card__property-upsell {
  isolation: isolate;
  display: flex;
  flex-direction: column;
  align-items: self-end;
  direction: ltr /* rtl: ignore */;

  .property-upsell__text {
    color: white;
    border: 0.5px solid rgba(255, 255, 255, 0.30);
    background: rgba(0, 0, 0, 0.60);
    padding: 2px 8px;
    margin-right: 4px /* rtl: ignore */;
    margin-bottom: var(--need-margin-bottom, 4px); // fixed 避免黑五的腰带异形部分会遮挡
    border-radius: 5000px;
    font-weight: 400;
    font-size: 11px;
    line-height: normal;
  }
  .property-upsell__img {
    width: 100%;
    height: auto;
  }
}
</style>
